import { Outlet } from "react-router-dom";
import logo from "../images/logo.svg";
import Header from "./Header";
import Footer from "./Footer";

const MainLayout = (props) => {
  return (
    <div>
      <Header logo={logo} />
      <Outlet />
      <Footer logo={logo} />
    </div>
  );
};

export default MainLayout;
